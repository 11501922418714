import React from "react";
import { ModuleWrapper } from "../moduleWrapper";
import { PortableTextBlock } from "@components/sanity/portableTextBlock";

const DoubleCardBlock = ({ config, cards }) => {
  return (
    <ModuleWrapper {...config}>
      <div className="grid grid-cols-1 md:grid-cols-2 px-gutter gap-[72px]">
        {cards?.map((card, index) => (
          <div key={index} className="bg-white p-[30px] rounded-[10px]">
            <h2 className="text-4xl text-red pb-[34px] border-b border-red mb-[34px] ">
              {card?.title}
            </h2>
            <PortableTextBlock className="text-[#686868]" text={card?.text} />
          </div>
        ))}
      </div>
    </ModuleWrapper>
  );
};

export default DoubleCardBlock;
